<template>
  <div>
    <p class="mb-3">
      Which are valid Lewis structures for thiocyanate,
      <stemble-latex content="$\ce{SCN^-?}$" />
    </p>

    <p class="mb-6 pl-3">
      <img src="/img/assignments/SCN_LS.png" width="95%" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question326',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
      },
      options: [
        {text: '$\\text{Structures D and E}$', value: 'de'},
        {text: '$\\text{Structures A and C}$', value: 'ac'},
        {text: '$\\text{Structures B and D}$', value: 'bd'},
        {text: '$\\text{Structures A and E}$', value: 'ae'},
        {text: '$\\text{Structures B and C}$', value: 'bc'},
      ],
    };
  },
};
</script>
